<template>
  <div class="detail-view">
    <!-- 返回按钮 -->
    <button @click="$emit('back')" class="back-button">返回</button>
    <div class="detail-content">
      <!-- 标题，点击可转换为拼音并朗读 -->
      <h1 @click="textToSpeech(detail.title)" v-html="convertToPinyin(detail.title, pinyinData.title)"></h1>
      <!-- 作者，点击可转换为拼音并朗读 -->
      <h2 @click="textToSpeech(detail.author)" v-html="convertToPinyin(detail.author, pinyinData.author)"></h2>
      <!-- 格式化内容展示区 -->
      <div class="formatted-content" v-html="formattedContent"></div>
      <!-- 选择 TTS 服务 -->
      <div class="tts-controls">
        <select v-model="selectedTTS">
          <option value="tts1">一号选手</option>
          <option value="tts2">二号选手</option>
        </select>
        <!-- 朗读内容按钮 -->
        <button @click="textToSpeech(detail.content)" class="tts-button">朗读</button>
      </div>
    </div>
    <!-- 加载中遮罩层 -->
    <div v-if="loading" class="loading-overlay">
      <div class="spinner"></div>
    </div>
    <!-- 拼音加载提示浮窗 -->
    <div v-if="loadingPinyin" class="pinyin-loading-toast">
      正在思考读音，请耐心等候...
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'DetailView',
  props: ['item'],
  data() {
    return {
      detail: {}, // 详情数据
      loading: true, // 加载状态
      loadingPinyin: false, // 拼音加载状态
      formattedContent: '', // 格式化后的内容
      audioCache: {}, // 语音缓存
      selectedTTS: 'tts1', // 选择的 TTS 服务，默认是 tts1
      pinyinData: { title: {}, author: {}, content: {} }, // 拼音数据
    };
  },
  watch: {
    // 监听 item 的变化，调用 fetchDetail 方法
    item: 'fetchDetail'
  },
  methods: {
    // 获取详情数据
    fetchDetail() {
      this.loading = true;
      axios.get(`https://api.shici.asia/detail`, {
        params: {
          type: this.item.type,
          title: this.item.title,
          author: this.item.author
        }
      })
      .then(response => {
        this.detail = response.data;
        this.formatContent();
        this.loading = false; // 正文内容加载完成后隐藏等待动画
        this.getPinyinData();
      })
      .catch(error => {
        console.error(error);
        this.loading = false;
      });
    },
    // 格式化内容
    formatContent() {
      if (this.detail.content) {
        const paragraphs = JSON.parse(this.detail.content);
        this.formattedContent = paragraphs.map(paragraph => `<p>${paragraph.trim()}</p>`).join('');
      }
    },
    // 获取拼音数据
    async getPinyinData() {
      this.loadingPinyin = true;
      const paragraphs = JSON.parse(this.detail.content);

      const processParagraph = async (paragraph, index) => {
        const cleanParagraph = paragraph.replace(/[^\u4e00-\u9fa5，。]/g, '');
        try {
          const response = await axios.post('https://api.shici.asia/pinyin', { text: cleanParagraph });
          const data = response.data;
          this.pinyinData.content[index] = data;
          this.formatContentWithPinyin(); // 每次请求成功后立即显示结果
        } catch (error) {
          console.error(error);
        }
      };

      const promises = paragraphs.map((paragraph, index) => processParagraph(paragraph, index));
      await Promise.all(promises);

      // 获取标题和作者的拼音
      try {
        const titleResponse = await axios.post('https://api.shici.asia/pinyin', { text: this.detail.title.replace(/[^\u4e00-\u9fa5，。]/g, '') });
        this.pinyinData.title = titleResponse.data;
        this.formatContentWithPinyin(); // 每次请求成功后立即显示结果
      } catch (error) {
        console.error(error);
      }

      try {
        const authorResponse = await axios.post('https://api.shici.asia/pinyin', { text: this.detail.author.replace(/[^\u4e00-\u9fa5，。]/g, '') });
        this.pinyinData.author = authorResponse.data;
        this.formatContentWithPinyin(); // 每次请求成功后立即显示结果
      } catch (error) {
        console.error(error);
      }

      this.loadingPinyin = false;
      this.checkMissingPinyin();
    },
    // 检查是否有缺失拼音的部分，并重新请求
    async checkMissingPinyin() {
      const missingCharacters = [];

      // 检查正文内容
      const contentText = this.detail.content.replace(/[^\u4e00-\u9fa5]/g, '');
      for (const char of contentText) {
        if (!this.pinyinData.content[char]) {
          missingCharacters.push(char);
        }
      }

      // 检查标题和作者
      const titleText = this.detail.title.replace(/[^\u4e00-\u9fa5]/g, '');
      for (const char of titleText) {
        if (!this.pinyinData.title[char]) {
          missingCharacters.push(char);
        }
      }

      const authorText = this.detail.author.replace(/[^\u4e00-\u9fa5]/g, '');
      for (const char of authorText) {
        if (!this.pinyinData.author[char]) {
          missingCharacters.push(char);
        }
      }

      if (missingCharacters.length > 0) {
        this.loadingPinyin = true;
        const uniqueMissingCharacters = [...new Set(missingCharacters)].join('');
        try {
          const response = await axios.post('https://api.shici.asia/pinyin', { text: uniqueMissingCharacters });
          const data = response.data;
          this.pinyinData.content = { ...this.pinyinData.content, ...data };
          this.pinyinData.title = { ...this.pinyinData.title, ...data };
          this.pinyinData.author = { ...this.pinyinData.author, ...data };
          this.formatContentWithPinyin();
        } catch (error) {
          console.error(error);
        }
        this.loadingPinyin = false;
      }
    },
    // 将文本转换为拼音
    convertToPinyin(text, pinyinDict) {
      if (!text || !pinyinDict) {
        return text;
      }

      return text.split('').map(char => {
        if (/[^\u4e00-\u9fa5]/.test(char)) {
          return `<span class="non-chinese">${char}</span>`; // 返回非中文字符
        }
        return `<ruby class="pinyin-ruby">${char}<rt>${pinyinDict[char] || ''}</rt></ruby>`;
      }).join('');
    },
    // 格式化内容并添加拼音
    formatContentWithPinyin() {
      if (this.detail.content) {
        const paragraphs = JSON.parse(this.detail.content);
        this.formattedContent = paragraphs.map((paragraph, index) => `<p>${this.convertToPinyin(paragraph.trim(), this.pinyinData.content[index] || {})}</p>`).join('');
      }
    },
    // 文本转语音
    textToSpeech(text) {
      if (this.audioCache[text]) {
        const audio = new Audio(this.audioCache[text]);
        audio.play();
      } else {
        axios.post(`https://api.shici.asia/${this.selectedTTS}`, { text: text }, { responseType: 'blob' })
          .then(response => {
            const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });
            const audioUrl = URL.createObjectURL(audioBlob);
            this.audioCache[text] = audioUrl;
            const audio = new Audio(audioUrl);
            audio.play();
          })
          .catch(error => {
            console.error(error);
          });
      }
    }
  },
  // 组件挂载后获取详情数据
  mounted() {
    this.fetchDetail();
  }
};
</script>

<style scoped>
.detail-view {
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.back-button {
  position: absolute;
  top: 10px;
  right: 10px; /* 修改为右侧 */
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.back-button:hover {
  background-color: #0056b3;
}

.detail-content {
  text-align: center;
  width: 100%;
  margin-top: 50px; /* 增加顶部外边距，使内容与返回按钮有一定距离 */
}

.detail-content h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
  cursor: pointer;
}

.detail-content h2 {
  font-size: 1.5em;
  color: #555;
  margin-bottom: 20px;
  cursor: pointer;
}

.formatted-content {
  font-size: 1.2em;
  text-align: center;
  white-space: pre-wrap;
  word-wrap: break-word;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin-bottom: 20px;
}

.formatted-content p {
  margin: 10px 0;
}

.tts-controls {
  display: flex;
  align-items: center;
}

.tts-controls select {
  padding: 10px;
  font-size: 16px;
  margin-right: 10px;
}

.tts-button {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #28a745;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.tts-button:hover {
  background-color: #218838;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #007bff;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* ruby和rt元素的样式，用于居中文本 */
.pinyin-ruby {
  display: inline-table;
  text-align: center;
  margin: 0 0.1em; /* 调整ruby元素间的间距 */
}

.pinyin-ruby > rt {
  font-size: 0.6em; /* 根据需要调整以适应设计 */
  line-height: 1.2em;
  color: #555;
  display: table-row;
  text-align: center;
}

.non-chinese {
  display: inline-block;
  width: 1em; /* 确保非中文字符占用相同的空间 */
  text-align: center;
}

.pinyin-loading-toast {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 1000;
}

/* 响应式样式 */
@media (max-width: 600px) {
  .detail-view {
    padding: 10px;
  }

  .detail-content h1 {
    font-size: 1.8em;
  }

  .detail-content h2 {
    font-size: 1.2em;
  }

  .formatted-content {
    font-size: 1em;
    padding: 15px;
  }

  .tts-button {
    padding: 8px 16px;
    font-size: 14px;
  }
}
</style>
