<template>
  <div class="search-view">
    <h1>经典诵读平台</h1>
    <div class="search-bar-container" :class="{ 'results-shown': results.length > 0 }">
      <div class="search-bar">
        <select v-model="category">
          <option value="综合">综合</option>
          <option value="唐诗">唐诗</option>
          <option value="宋词">宋词</option>
          <option value="元曲">元曲</option>
          <option value="启蒙经典">启蒙经典</option>
        </select>
        <input v-model="query" @keyup.enter="search" placeholder="输入作者或古诗标题"/>
        <button @click="search">搜索</button>
      </div>
      <p v-if="!query" class="placeholder-text">请输入作者或诗词名进行搜索</p>
    </div>
    <div v-if="loading" class="loading-overlay">
      <div class="spinner"></div>
    </div>
    <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
    <transition-group name="list" tag="ul" class="results-list">
      <li v-for="item in results" :key="item.title" @click="showDetail(item)" class="result-item">
        {{ item.type }} - {{ item.title }} 作者： {{ item.author }}
      </li>
    </transition-group>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'SearchView',
  props: ['initialQuery'],
  data() {
    return {
      query: '',
      category: '综合', // 默认值为综合
      results: [],
      loading: false,
      errorMessage: null // 用于存储错误消息
    };
  },
  watch: {
    initialQuery: {
      immediate: true,
      handler(newQuery) {
        if (newQuery) {
          this.category = newQuery; // 设置选择框的初始值
          this.search();
        }
      }
    }
  },
  methods: {
    search() {
      if (!this.query) return;
      this.loading = true;
      axios.get(`https://api.shici.asia/search`, {
        params: {
          query: this.query,
          category: this.category
        }
      })
      .then(response => {
        this.results = response.data;
        this.loading = false;
      })
      .catch(error => {
        this.errorMessage = error.response?.data?.message || '搜索失败'; // 存储错误消息
        this.loading = false;
      });
    },
    showDetail(item) {
      this.$emit('show-detail', item);
    }
  }
};
</script>

<style scoped>
.search-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin: 50px auto;
  text-align: center;
}

.search-bar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  transition: all 0.5s;
}

.search-bar-container.results-shown {
  align-items: flex-start;
}

.search-bar {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  margin-bottom: 10px;
}

.search-bar select {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
  margin-right: -1px; /* 使边框重叠 */
}

.search-bar input {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 0 0 0 0; /* 移除边框圆角 */
}

.search-bar button {
  padding: 10px 20px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-left: none;
  background-color: #007bff;
  color: white;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
}

.search-bar button:hover {
  background-color: #0056b3;
}

.placeholder-text {
  font-size: 14px;
  color: #999;
}

.error-message {
  color: red;
}

.results-list {
  list-style: none;
  padding: 0;
  width: 100%;
  max-width: 600px;
}

.result-item {
  padding: 10px;
  margin: 5px 0;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.2s, background-color 0.2s;
}

.result-item:hover {
  background-color: #f1f1f1;
  transform: scale(1.02);
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #007bff;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Responsive styles */
@media (max-width: 600px) {
  .search-view {
    padding: 10px;
  }

  .search-bar input {
    width: auto;
  }

  .search-bar button {
    padding: 8px 16px;
    font-size: 14px;
  }

  .result-item {
    font-size: 14px;
    padding: 8px;
  }
}
</style>
