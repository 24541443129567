<template>
  <div class="welcome-view">
    <h1>欢迎来到经典诵读平台</h1>
    <p class="credits">
      经典诵读总体设计者：孙兆光<br>
      经典诵读平台开发者：丁子攸
    </p>
    <div class="button-container">
      <button @click="selectCategory('综合')">综合</button>
      <button @click="selectCategory('唐诗')">唐诗</button>
      <button @click="selectCategory('宋词')">宋词</button>
      <button @click="selectCategory('元曲')">元曲</button>
      <button @click="selectCategory('启蒙经典')">启蒙经典</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WelcomeView',
  methods: {
    selectCategory(category) {
      this.$emit('select-category', category);
    }
  }
};
</script>

<style scoped>
.welcome-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: url('@/assets/background.png'); /* 引用背景图片 */
  background-size: cover; /* 背景图片覆盖整个容器 */
  background-position: center; /* 背景图片居中 */
  background-repeat: no-repeat; /* 背景图片不重复 */
}

.welcome-view h1 {
  font-size: clamp(2em, 5vw, 3em); /* 使用 clamp 函数设置字体大小 */
  margin-bottom: 20px;
  color: white; /* 根据背景图片调整文字颜色 */
  text-align: center; /* 居中对齐 */
}

.credits {
  font-size: clamp(1em, 2vw, 1.5em); /* 使用 clamp 函数设置字体大小 */
  color: white; /* 根据背景图片调整文字颜色 */
  text-align: center; /* 居中对齐 */
  margin-bottom: 20px; /* 增加与按钮之间的间距 */
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; /* 确保容器宽度一致 */
}

.welcome-view button {
  width: 200px; /* 设置按钮的固定宽度 */
  padding: 10px 20px;
  font-size: clamp(1em, 2.5vw, 1.5em); /* 使用 clamp 函数设置按钮文字的字体大小 */
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 0;
}

.welcome-view button:hover {
  background-color: #0056b3;
}
</style>
