<template>
  <div id="app">
    <WelcomeView v-if="showWelcomePage" @select-category="handleSelectCategory" />
    <SearchView v-if="!showWelcomePage && !showDetailPage" @show-detail="handleShowDetail" :initialQuery="initialQuery" />
    <DetailView v-if="!showWelcomePage && showDetailPage" :item="selectedItem" @back="handleBack" />
  </div>
</template>

<script>
import SearchView from './components/SearchView.vue';
import DetailView from './components/DetailView.vue';
import WelcomeView from './components/WelcomeView.vue';

export default {
  components: {
    SearchView,
    DetailView,
    WelcomeView
  },
  data() {
    return {
      showWelcomePage: true,
      showDetailPage: false,
      selectedItem: null,
      initialQuery: ''
    };
  },
  methods: {
    handleSelectCategory(category) {
      this.initialQuery = category;
      this.showWelcomePage = false;
    },
    handleShowDetail(item) {
      this.selectedItem = item;
      this.showDetailPage = true;
    },
    handleBack() {
      this.showDetailPage = false;
      this.selectedItem = null;
    }
  }
};
</script>
